import {
    HTTP
} from '../http-common'


export default {
    state: {
        loading: false,
        motivationList: [],
        salesList: [],
        stockList: [],
        shopsList: [],
        reportData: [],
        reportsDialog: false,
        stockDocs: [],
        stockDocsDialog: false,
        imeiTypeList: [],
        imeiList: [],
        imeiDocs: []
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setMotivationList(state, payload) {
            state.motivationList = payload
        },
        setSalesList(state, payload) {
            state.salesList = payload
        },
        setStockList(state, payload) {
            state.stockList = payload
        },
        setShopsList(state, payload) {
            state.shopsList = payload
        },
        setReportData(state, payload) {
            state.reportData = payload
        },
        setReportsDialog(state, payload) {
            state.reportsDialog = payload
        },
        setStockDocs(state, payload) {
            state.stockDocs = payload
        },
        setStockDocsDialog(state, payload) {
            state.stockDocsDialog = payload
        },
        setImeiTypeListData(state, payload) {
            state.imeiTypeList = payload
        },
        setImeiList(state, payload) {
            state.imeiList = payload
        },
        setImeiDocs(state, payload) {
            state.imeiDocs = payload
        },
    },
    actions: {
        loadSalesReportsList(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/load-sales-reports-list', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setSalesList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        loadStockReportsList(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/load-stock-reports-list', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setStockList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getMotivationList(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/get-motivation-list', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setMotivationList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getShopsForReport(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/get-shops-for-report', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setShopsList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getShopsForImeiReport(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/get-shops-for-imei-report', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setShopsList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        exportSales(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/export-sales', options)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        exportStock(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/export-stock', options)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        downloadTemplate(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/download-template', options)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendSalesReportForm(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/send-sales-report-form', options, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setReportData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendStockReportForm(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/send-stock-report-form', options, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setReportData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendPhotoReportForm(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/send-photo-report-form', options, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        submitSales(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/submit-sales', options)
                    .then(response => {
                        commit('setLoading', false)
                        resolve()
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        submitStock(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/submit-stock', options)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data.content) {
                            commit('setStockDocs', response.data.content.docs)
                            commit('setStockDocsDialog', true)
                        }
                        resolve()
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        deleteStockReport(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/delete-stock-report', options)
                    .then(response => {
                        commit('setLoading', false)
                        resolve()
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        deleteStockPhoto(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/delete-stock-photo', options)
                    .then(response => {
                        commit('setLoading', false)
                        resolve()
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getImeiTypeList(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/get-imei-type-list', { params })
                    .then(response => {
                        commit('setLoading', false)
                        commit('setImeiTypeListData', response.data.content)
                        resolve()
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendImeiReportForm(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/send-imei-report-form', options, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setReportData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        loadImeiReportsList(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/reports/load-imei-reports-list', { params })
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setImeiList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        exportImei(
            {
                commit,
            }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/reports/export-imei', options)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
    }
}